@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.PromoLarge';

#{$name} {
    color: $white;


    &-wrapper {
        overflow: hidden;
        position: relative;
    }

    &-inner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 225px;
        position: relative;
        z-index: 1;

        &-content-right {
            @include media-breakpoint-up(md) {
                margin-left: auto;
                max-width: 40%;
            }
        }

        &-content-left {
            @include media-breakpoint-up(md) {
                margin-right: auto;
                max-width: 40%;
            }
        }

        &-content-center {
            @include media-breakpoint-up(md) {
                margin-left: auto;
                margin-right: auto;
                max-width: 40%;
            }
        }


        @include media-breakpoint-up(md) {
            min-height: 300px;
        }
    }

    &-link {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 225px;

        @include media-breakpoint-up(md) {
            min-height: 300px;
        }

        &:link, &:visited, &:active &:hover {
            color: $white;
            text-decoration: none;
        }

        @include media-breakpoint-up(sm) {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: $primary;
                opacity: 0;
                z-index: -1;
                transition: all linear .2s;
            }
        }

        &:hover {
            transition: all linear .2s;

            @include media-breakpoint-up(sm) {
                &::after {
                    opacity: .8;
                }
            }
        }
    }

    &-title {
        margin: 1rem 1rem .5rem;
        font-size: 1.25rem;

        @include media-breakpoint-up(sm) {
            font-size: 1.6rem;
        }

        @include media-breakpoint-up(md) {
            margin-left: 2rem;
            margin-right: 2rem;
            font-size: 1.6rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }
    }

    &-intro {
        margin: 0 1em;

        @include media-breakpoint-up(sm) {
            font-size: .985rem;
        }

        @include media-breakpoint-up(md) {
            margin: 0 2rem;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 3rem;
        }
    }

    &-media {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(1, 1);
        max-width: 100%;
        z-index: 0;
        transition: all linear .3s;
        backface-visibility: hidden;
    }


    &-buttonWrapper {
        padding-top: 1rem;

        @include media-breakpoint-up(sm) {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all linear .2s;
            padding-top: 0;

            #{$name}:hover & {
                opacity: 1;
                max-height: 65px;
                padding-top: 1rem;
            }
        }
    }
}
